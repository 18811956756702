import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  CmBaseCompoundFilterIntl,
  CmObjectFieldListData,
  CmObjectFieldListIntl,
  CmPaginateTableIntl,
  CmUiCompoundFilterModule,
  CmUiFormModule,
  CmUiLoaderModule,
  CmUiNavbarModule,
  CmUiTableModule,
  TopBarIntl,
} from '@cm/ui-modules';
import {CookieService} from 'ngx-cookie-service';

import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';

import {MatListModule} from '@angular/material/list';
import {MatChipsModule} from '@angular/material/chips';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {TranslateModule} from '@ngx-translate/core';
import {DeleteConfirmationDialogComponent} from './delete-confirmation-dialog/delete-confirmation-dialog.component';
import {ExportConfirmationDialogComponent} from './export-confirmation-dialog/export-confirmation-dialog.component';
import {ConvertConfirmationDialogComponent} from './convert-confirmation-dialog/convert-confirmation-dialog.component';
import { LANG } from 'src/app/core/injectors';

const MATERIAL_MODULES = [
  MatDialogModule,
  MatButtonModule,
  MatIconModule,
  MatListModule,
  MatChipsModule,
  MatTableModule,
  MatSortModule,
  MatSnackBarModule,
  TranslateModule ,
];

const ENTRY_COMPONENTS = [DeleteConfirmationDialogComponent, ExportConfirmationDialogComponent];

const COMPONENTS = [...ENTRY_COMPONENTS];

const CM_UI_MODULES = [
  CmUiTableModule,
  CmUiNavbarModule,
  CmUiFormModule,
  CmUiCompoundFilterModule,
  CmUiLoaderModule,
];
const appRootElement = document.querySelector('app-root');

@NgModule({
  imports: [CommonModule, ...CM_UI_MODULES, ...MATERIAL_MODULES],
  exports: [...CM_UI_MODULES, ...MATERIAL_MODULES, ...COMPONENTS, TranslateModule],
  providers: [
    {
      provide: LANG,
      useValue: appRootElement.getAttribute('lang') || navigator.language || 'en',
    },
    CookieService, 
    CmBaseCompoundFilterIntl,
    CmObjectFieldListData,
    CmObjectFieldListIntl,
    CmPaginateTableIntl,
    TopBarIntl,],
  declarations: [...COMPONENTS, ConvertConfirmationDialogComponent],
})
export class CoreModule {}
